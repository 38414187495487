import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

const DatenschutzPage = () => {
  return (
    <Layout>
      <BlockWrapper block={{ headline: "Datenschutz" }}>
        <div id="hassiaPrivacyPolicy" data-hpp-date="20230922">
          <h2>1. Datenschutz auf einen Blick</h2>
          <h3>Allgemeine Hinweise</h3>
          <p>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </p>
          <p>
            Dieses Online-Angebot richtet sich nicht an Kinder unter 16 Jahren.
            Personen, die das 16. Lebensjahr noch nicht vollendet haben, dürfen
            ohne Zustimmung der Erziehungsberechtigten keine personenbezogenen
            Daten an uns übermitteln.
          </p>
          <h3>Datenerfassung auf dieser Website</h3>
          <p>
            <b>
              Wer ist verantwortlich für die Datenerfassung auf dieser Website?
            </b>
          </p>
          <p>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
            „Hinweis zum Verantwortlichen“ in dieser Datenschutzerklärung
            entnehmen.
          </p>
          <p>
            <b>Wie erfassen wir Ihre Daten?</b>
          </p>
          <p>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben.
          </p>{" "}
          <p>
            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
            Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
            allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
            Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
            automatisch, sobald Sie diese Website betreten.
          </p>{" "}
          <p>
            <b>Wofür nutzen wir Ihre Daten?</b>
          </p>{" "}
          <p>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </p>{" "}
          <p>
            <b>Welche Rechte haben Sie bezüglich Ihrer Daten?</b>
          </p>{" "}
          <p>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
            Datenverarbeitung erteilt haben, können Sie diese Einwilligung
            jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
            unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </p>{" "}
          <p>
            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
            sich jederzeit an uns wenden.
          </p>
          <h3>Analyse-Tools und Tools von Drittanbietern</h3>
          <p>
            Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor allem mit sogenannten
            Analyseprogrammen.
          </p>{" "}
          <p>
            Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in
            der folgenden Datenschutzerklärung.
          </p>
          <h2>2. Hosting</h2>
          <h3>Externes Hosting</h3>
          <p>
            Diese Website wird extern gehostet. Die personenbezogenen Daten, die
            auf dieser Website erfasst werden, werden auf den Servern des
            Hosters / der Hoster gespeichert. Hierbei kann es sich v. a. um
            IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
            Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
            Daten, die über eine Website generiert werden, handeln.
          </p>{" "}
          <p></p>
          <p>
            Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung
            gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
            lit. b DSGVO) und im Interesse einer sicheren, schnellen und
            effizienten Bereitstellung unseres Online-Angebots durch einen
            professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
            Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
            oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
            Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
            ist jederzeit widerrufbar.
          </p>
          <p>
            Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit
            verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten
            erforderlich ist und unsere Weisungen in Bezug auf diese Daten
            befolgen.
          </p>
          <p>
            <b>Abschluss eines Vertrages über Auftragsverarbeitung</b>
          </p>
          <p>
            Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir
            einen Vertrag über Auftragsverarbeitung mit unserem Hoster innerhalb
            der EU geschlossen.
          </p>
          <p>
            Hierbei handelt es sich um einen datenschutzrechtlich
            vorgeschriebenen Vertrag, der gewährleistet, dass dieser die
            personenbezogenen Daten unserer Websitebesucher nur nach unseren
            Weisungen und unter Einhaltung der DSGVO verarbeitet.
          </p>
          <h2>3. Allgemeine Hinweise und Pflichtinformationen</h2>
          <h3>Datenschutz</h3>
          <p>
            Die Betreiber dieser Website nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend den gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
          </p>{" "}
          <p>
            Wenn Sie diese Website benutzen, werden verschiedene
            personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
            mit denen Sie persönlich identifiziert werden können. Die
            vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
            und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
            Zweck das geschieht.
          </p>{" "}
          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
          </p>
          <h3>Hinweis zum Verantwortlichen</h3>
          <p>
            Der Verantwortliche für die Datenverarbeitung auf dieser Website
            ist:
          </p>
          <div id="hpp-controller">
            <p>
              Rapp's Kelterei GmbH
              <br />
              Brunnenstraße 1<br />
              61184 Karben
            </p>
            <p>
              Telefon: +49 (0) 6039 / 91 94-0
              <br />
              E-Mail: info@rapps.de
            </p>
          </div>
          <p>
            Verantwortlicher ist die natürliche oder juristische Person, die
            allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z. B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
          </p>
          <h3>Speicherdauer</h3>
          <p>
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere
            Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
            bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
            ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
            zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
            wir keine anderen rechtlich zulässigen Gründe für die Speicherung
            Ihrer personenbezogenen Daten haben (z. B. steuer- oder
            handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
            erfolgt die Löschung nach Fortfall dieser Gründe.
          </p>
          <h3>
            Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung
            auf dieser Website
          </h3>
          <p>
            Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten
            wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1
            lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
            Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im
            Falle einer ausdrücklichen Einwilligung in die Übertragung
            personenbezogener Daten in Drittstaaten erfolgt die
            Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a
            DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff
            auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting)
            eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf
            Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit
            widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur
            Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten
            wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
            Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung
            einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von
            Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf
            Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
            DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen
            Rechtsgrundlagen wird in den folgenden Absätzen dieser
            Datenschutzerklärung informiert.
          </p>
          <h3>Datenschutzbeauftragter</h3>
          <p></p>
          <p>Wir haben einen Datenschutzbeauftragten benannt.</p>
          <div id="hpp-data_protection_officer">
            {" "}
            <p>
              RPA Datenschutz + Compliance GmbH
              <br /> Herr Henning Koch und Herr Ilja Borchers
              <br /> Hauser Gasse 19b
              <br /> 35578 Wetzlar
            </p>{" "}
            <p>E-Mail: datenschutz@rapps.de</p>
          </div>
          <h3>Hinweis zur Datenweitergabe in die USA</h3>
          <p></p>
          <p>
            Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den
            USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten.
            Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in
            diese Drittstaaten übertragen und dort verarbeitet werden. Wir
            weisen darauf hin, dass in diesen Ländern kein mit der EU
            vergleichbares Datenschutzniveau garantiert werden kann.
            Beispielsweise sind US-Unternehmen dazu verpflichtet,
            personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne
            dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es
            kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B.
            Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
            Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern.
            Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
          </p>
          <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
          <p>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit mit Wirkung für die Zukunft widerrufen.
          </p>
          <p>
            Sofern es um die Verwendung Ihrer E-Mail-Adresse geht, reicht eine
            formlose Mitteilung von der betroffenen E-Mail-Adresse an{" "}
            <a href="mailto:datenschutz@rapps.de">datenschutz@rapps.de</a>.
          </p>
          <p>
            Ihre sonstigen Einwilligungen in Datenverarbeitungen auf dieser
            Website können Sie mit Wirkung im aktuell verwendeten
            Endgerät/Browser dauerhaft widerrufen, indem Sie Ihre{" "}
            <a href="#" className="hc-show-panel">
              Datenschutzeinstellungen
            </a>{" "}
            bearbeiten
            <noscript>
              {" "}
              <mark>
                <em>
                  (dieser Link ist momentan nicht funktionsfähig, weil Ihr
                  Browser oder ein Browser-Plugin die Ausführung von JavaScript
                  auf dieser Seite verbietet)
                </em>
              </mark>
            </noscript>
            .
          </p>
          <p>
            Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
            bleibt vom Widerruf unberührt.
          </p>
          <h3 id="art-21-dsgvo">
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen Direktwerbung (Art. 21 DSGVO)
          </h3>
          <p>
            <b>
              Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e
              oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen,
              die sich aus Ihrer besonderen Situation ergeben, gegen die
              Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen;
              dies gilt auch für ein auf diese Bestimmungen gestütztes
              Profiling. Die jeweilige Rechtsgrundlage, auf denen eine
              Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung.
              Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen
              personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir
              können zwingende schutzwürdige Gründe für die Verarbeitung
              nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen
              oder die Verarbeitung dient der Geltendmachung, Ausübung oder
              Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1
              DSGVO).
            </b>
          </p>
          <p>
            <b>
              Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung
              zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen
              die Verarbeitung Sie betreffender personenbezogener Daten zum
              Zwecke derartiger Werbung einzulegen; dies gilt auch für das
              Profiling, soweit es mit solcher Direktwerbung in Verbindung
              steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten
              anschließend nicht mehr zum Zwecke der Direktwerbung verwendet
              (Widerspruch nach Art. 21 Abs. 2 DSGVO).
            </b>
          </p>
          <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
          <p>
            Den Betroffenen steht jederzeit ein Beschwerderecht bei einer
            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
            gewöhnlichen Aufenthalts zu. Das Beschwerderecht besteht unbeschadet
            eines etwaigen Verstoßes gegen datenschutzrechtliche Vorschriften
            oder anderweitiger verwaltungsrechtlicher oder gerichtlicher
            Rechtsbehelfe.
          </p>
          <p>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen
            jederzeit ein Beschwerderecht bei einer Aufsichtsbehörde,
            insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts,
            ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu.
            Das Beschwerderecht besteht unbeschadet anderweitiger
            verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
          </p>
          <p>
            Die für uns zuständige Datenschutzaufsichtsbehörde, bei der eine
            Beschwerde über eine Verletzung von Datenschutzrecht eingereicht
            werden kann, ist:
          </p>
          <div id="hpp-supervisory_authority">
            <p>
              Der Hessische Datenschutzbeauftragte
              <br />
              Gustav-Stresemann-Ring 1<br />
              65189 Wiesbaden
              <br />
              Telefon: +49 (0) 611 14080
              <br />
              Telefax: +49 (0) 611 1408 - 900
              <br />
              Webseite:{" "}
              <a href="https://www.datenschutz.hessen.de">
                https://www.datenschutz.hessen.de
              </a>
              <br />
              E-Mail:{" "}
              <a href="mailto:poststelle@datenschutz.hessen.de">
                poststelle@datenschutz.hessen.de
              </a>
            </p>
          </div>
          <p>
            Falls Sie von einem Beschwerderecht bei der Aufsichtsbehörde
            Gebrauch machen möchten, laden wir Sie ein, mit uns zuvor in Kontakt
            zu treten und noch einmal auf uns zuzugehen (z. B. unter E-Mail:{" "}
            <a href="mailto:datenschutz@rapps.de">datenschutz@rapps.de</a>).
          </p>
          <h3>Recht auf Datenübertragbarkeit</h3>
          <p>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </p>
          <h3>Auskunft, Berichtigung und Löschung</h3>
          <p>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an
            uns wenden.
          </p>
          <h3>Recht auf Einschränkung der Verarbeitung</h3>
          <p>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit an uns wenden. Das Recht auf Einschränkung der
            Verarbeitung besteht in folgenden Fällen:
          </p>
          <ul>
            {" "}
            <li>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, benötigen wir in der Regel
              Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
              das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>{" "}
            <li>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
              geschah/geschieht, können Sie statt der Löschung die Einschränkung
              der Datenverarbeitung verlangen.
            </li>{" "}
            <li>
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
              sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der
              Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>{" "}
            <li>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
              haben, muss eine Abwägung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen
              Interessen überwiegen, haben Sie das Recht, die Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
          </ul>
          <p>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
            eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
            abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen
            Union oder eines Mitgliedstaats verarbeitet werden.
          </p>
          <h3>SSL- bzw. TLS-Verschlüsselung</h3>
          <p>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
            bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen
            Sie daran, dass die Adresszeile des Browsers von „http://“ auf
            „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          </p>{" "}
          <p>
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </p>
          <h3>Widerspruch gegen Werbe-E-Mails</h3>
          <p>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
            Werbung und Informationsmaterialien wird hiermit widersprochen. Die
            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
            im Falle der unverlangten Zusendung von Werbeinformationen, etwa
            durch Spam-E-Mails, vor.
          </p>
          <h2>4. Datenerfassung auf dieser Website</h2>
          <div id="hpp-cookies">
            {" "}
            <h3>Cookies und Lokalspeicherobjekte (LSO)</h3>{" "}
            <p>
              Diese Website verwendet so genannte „Cookies“ oder auch so
              genannte Lokalspeicherobjekte (im folgenden LSO genannt). Beides
              sind Methoden der Speicherung kleiner Datenpakte in Form von
              Schlüssel-Wert-Paaren in Ihrem Browser.
            </p>{" "}
            <p>
              Cookies werden entweder für die Dauer einer Sitzung
              (Session-Cookies) oder bis zu einem bestimmten, vom Entwickler des
              jeweiligen Cookies vorgesehenen Verfallszeitpunkt gespeichert
              (dauerhafte Cookies). Session-Cookies werden im Regelfall nach
              Ende Ihres Besuchs automatisch gelöscht, wobei sich der Zeitpunkt
              der Löschung im Fall der Verwendung von Funktionen zur
              Sitzungswiederherstellung in modernen Browsern möglicherweise
              verzögern kann. Dauerhafte Cookies werden spätestens zu ihrem
              programmierten Verfallszeitpunkt von Ihrem Browser gelöscht. Falls
              Sie die Domain, von der das Cookie gesetzt wurde erneut aufrufen
              kann es sein, dass der Domain-Betreiber das Cookie in Ihrem
              Browser vorzeitig löscht. Sie können beide Formen von Cookies auch
              jederzeit über die Einstellungen Ihres Browsers vorzeitig selbst
              löschen, ohne eine bestimmte Website zu besuchen.
            </p>{" "}
            <p>
              Im Unterschied zu Cookies haben LSO in keinem Fall ein
              vorprogrammiertes Verfallsdatum. Sie können LSO jederzeit
              vorzeitig selbst löschen. Falls Sie die exakte Domain, von der ein
              LSO gesetzt wurde erneut aufrufen kann es sein, dass der
              Domain-Betreiber das LSO in Ihrem Browser löscht. Davon abgesehen
              führen manche Browser, auf Grundlage sich stetig fortentwickelnder
              Kriterien und/oder Browser-Einstellungen, eine vorzeitige Löschung
              sowohl von dauerhaften Cookies als auch von LSO selbständig durch.
            </p>{" "}
            <p></p>{" "}
            <p>
              Cookies und LSO können von uns (First-Party-Cookies/LSO) oder von
              Drittunternehmen stammen (sog. Third-Party-Cookies/LSO).
              Third-Party-Cookies/LSO ermöglichen die Einbindung bestimmter
              Dienstleistungen von Drittunternehmen innerhalb von Webseiten (z.
              B. Cookies/LSO zur Abwicklung von Zahlungsdienstleistungen).
            </p>{" "}
            <p>
              Cookies und LSO haben verschiedene Funktionen. Manche Cookies sind
              technisch notwendig, da bestimmte Webseitenfunktionen ohne diese
              nicht funktionieren würden (z. B. eine Warenkorb- oder eine
              Login-Funktion). Andere Cookies und LSO können zur Auswertung des
              Nutzerverhaltens verwendet werden.
            </p>{" "}
            <p>
              Cookies, die zur Durchführung des elektronischen
              Kommunikationsvorgangs, oder zur Bereitstellung bestimmter, von
              Ihnen erwünschter Funktionen (funktionale Cookies, z. B. für die
              Warenkorbfunktion) oder zur Optimierung der Website (z. B. Cookies
              zur Messung des Webpublikums) erforderlich sind (unbedingt
              erforderliche Cookies), werden auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage
              angegeben wird. Der Websitebetreiber hat ein berechtigtes
              Interesse an der Speicherung von unbedingt erforderlichen Cookies
              zur technisch fehlerfreien und optimierten Bereitstellung seiner
              Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und
              LSO abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
              Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25
              Abs. 1 TTDSG); die Einwilligung ist jederzeit widerrufbar.
            </p>{" "}
            <p>
              Sie können Ihren Browser u.U. so einstellen, dass Sie über das
              Setzen von Cookies informiert werden und Cookies nur im Einzelfall
              erlauben, die Annahme von Cookies für bestimmte Fälle oder
              generell ausschließen sowie das automatische Löschen der Cookies
              beim Schließen des Browsers aktivieren. Bei der Deaktivierung von
              Cookies kann die Funktionalität dieser Website eingeschränkt sein.
            </p>{" "}
            <p></p>{" "}
            <p>
              Welche Cookies und Dienste auf dieser Website eingesetzt werden,
              können Sie dieser Datenschutzerklärung entnehmen.
            </p>
          </div>
          <div id="hpp-essential_cookies">
            {" "}
            <h3>Unbedingt erforderliche Cookies (und LSO)</h3>{" "}
            <p>
              Unbedingt erforderliche Cookies und -Lokalspeicherobjekte (LSO)
              müssen gesetzt werden, um die korrekte Funktion einer Webseite zu
              gewährleisten. Vor allem Login-Daten und Warenkörbe werden auf
              diese Weise gespeichert.
            </p>{" "}
            <table>
              {" "}
              <colgroup>
                {" "}
                <col style={{ width: "18%" }} />{" "}
                <col style={{ width: "22%" }} />{" "}
                <col style={{ width: "42%" }} />{" "}
                <col style={{ width: "18%" }} />{" "}
              </colgroup>{" "}
              <thead>
                {" "}
                <tr>
                  {" "}
                  <th scope="col">Name</th>{" "}
                  <th scope="col">
                    Anbieter,
                    <br />
                    Domain,
                    <br />
                    Typ
                  </th>{" "}
                  <th scope="col">Zweck</th> <th scope="col">Ablauf</th>{" "}
                </tr>{" "}
              </thead>{" "}
              <tbody id="hpp-hassia_consent">
                {" "}
                <tr>
                  {" "}
                  <td scope="row">hc-domain-datum</td>{" "}
                  <td>
                    Seitenbetreiber,
                    <br />
                    datenschutz.hassia.com,
                    <br />
                    Cookie
                  </td>{" "}
                  <td>
                    Speichert den Zustimmungsstatus des Benutzers für bestimmte
                    Datenverarbeitungen auf der im Cookie-Namen enthaltenen
                    Domain. Das außerdem im Cookienamen enthaltene Datum
                    kennzeichnet die Version der zum Zeitpunkt der
                    Zustimmung/Ablehnung verwendeten Erklärungstexte und
                    Schaltflächen. Eine ggf. im Cookie enthaltene längere
                    Zahlenfolge ist ein Zeitstempel. Dieser dokumentiert den
                    Zeitpunkt der Ablehnung und verhindert, dass das
                    Sitzungs-Cookie durch die Funktion zur
                    Sitzungswiederherstellung moderner Browser potenziell über
                    die Dauer mehrerer Sitzungen nicht gelöscht wird.
                  </td>{" "}
                  <td>
                    6 Monate bei Zustimmung; bis Sitzungsende bzw. nach 30
                    Minuten Inaktivität bei Ablehnung
                  </td>{" "}
                </tr>{" "}
              </tbody>{" "}
              <tbody id="hpp-agency_cookies">
                {" "}
                <tr>
                  {" "}
                  <td scope="row">[login_cookie]</td>{" "}
                  <td>
                    {"{processor}"},<br />
                    rapps.de,
                    <br />
                    {"{Cookie / LSO}"}
                  </td>{" "}
                  <td>Ermöglicht die Login-Funktion auf der Website.</td>{" "}
                  <td>[X Tage]</td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td scope="row">[warenkorb_cookie]</td>{" "}
                  <td>
                    {"{processor}"},<br />
                    rapps.de,
                    <br />
                    {"{Cookie / LSO}"}
                  </td>{" "}
                  <td>Ermöglicht die Warenkorb-Funktion auf der Website.</td>{" "}
                  <td>[Y Tage]</td>{" "}
                </tr>{" "}
              </tbody>{" "}
            </table>
          </div>
          <div id="hpp-statistics_cookies">
            {" "}
            <h3>Statistik-Cookies (und -LSO)</h3>{" "}
            <p>
              Statistik-Cookies und -Lokalspeicherobjekte (LSO) helfen
              Websites-Betreibern zu verstehen, wie Besucher mit Websites
              interagieren, indem Informationen anonym gesammelt und gemeldet
              werden.
            </p>{" "}
            <table>
              {" "}
              <colgroup>
                {" "}
                <col style={{ width: "18%" }} />{" "}
                <col style={{ width: "22%" }} />{" "}
                <col style={{ width: "42%" }} />{" "}
                <col style={{ width: "18%" }} />{" "}
              </colgroup>{" "}
              <thead>
                {" "}
                <tr>
                  {" "}
                  <th scope="col">Name</th>{" "}
                  <th scope="col">
                    Anbieter,
                    <br />
                    Domain,
                    <br />
                    Typ
                  </th>{" "}
                  <th scope="col">Zweck</th> <th scope="col">Ablauf</th>{" "}
                </tr>{" "}
              </thead>{" "}
              <tbody id="hpp-matomo-cookies">
                {" "}
                <tr>
                  {" "}
                  <td scope="row">_pk_id#</td>{" "}
                  <td>
                    <a
                      href="https://www.yum.de/impressum/"
                      target="_blank"
                      rel="noopener"
                    >
                      YUM GmbH
                    </a>
                    ,<br />
                    rapps.de,
                    <br />
                    Cookie
                  </td>{" "}
                  <td>
                    Wird von <a href="#hpp-matomo">Matomo</a> verwendet, um
                    anonyme Statistiken zu erstellen über Besuche des Benutzers
                    auf der Website, wie z. B. die Anzahl der Besuche,
                    durchschnittliche Verweildauer auf der Website und welche
                    Seiten gelesen wurden.
                  </td>{" "}
                  <td>13 Monate</td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td scope="row">_pk_ref#</td>{" "}
                  <td>
                    <a
                      href="https://www.yum.de/impressum/"
                      target="_blank"
                      rel="noopener"
                    >
                      YUM GmbH
                    </a>
                    ,<br />
                    rapps.de,
                    <br />
                    Cookie
                  </td>{" "}
                  <td>
                    Wird von Matomo verwendet, um die Verweis-Website zu
                    identifizieren, über die der Besucher gekommen ist.
                  </td>{" "}
                  <td>6 Monate</td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td scope="row">_pk_ses#</td>{" "}
                  <td>
                    <a
                      href="https://www.yum.de/impressum/"
                      target="_blank"
                      rel="noopener"
                    >
                      YUM GmbH
                    </a>
                    ,<br />
                    rapps.de,
                    <br />
                    Cookie
                  </td>{" "}
                  <td>
                    Wird von Matomo verwendet, um Seitenabrufe des Besuchers
                    während der Sitzung auszuwerten.
                  </td>{" "}
                  <td>30 Minuten</td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td scope="row">_pk_testcookie</td>{" "}
                  <td>
                    <a
                      href="https://www.yum.de/impressum/"
                      target="_blank"
                      rel="noopener"
                    >
                      YUM GmbH
                    </a>
                    ,<br />
                    rapps.de,
                    <br />
                    Cookie
                  </td>{" "}
                  <td>
                    Wird von Matomo verwendet, um zu prüfen ob der Browser des
                    Besuchers Cookies akzeptiert. Es wird ohne jeden ID
                    generiert und sofort wieder gelöscht.
                  </td>{" "}
                  <td>Sofort</td>{" "}
                </tr>{" "}
              </tbody>{" "}
            </table>
          </div>
          <div id="hpp-functional_cookies">
            {" "}
            <h3>Funktionelle Cookies (und LSO)</h3>{" "}
            <p>
              Funktionelle Cookies/Lokalspeicherobjekte (LSO) ermöglichen
              weiterführende Funktionen - zum Beispiel die Bereitstellung von
              Videos auf Webseiten.
            </p>{" "}
            <table>
              {" "}
              <colgroup>
                {" "}
                <col style={{ width: "18%" }} />{" "}
                <col style={{ width: "22%" }} />{" "}
                <col style={{ width: "42%" }} />{" "}
                <col style={{ width: "18%" }} />{" "}
              </colgroup>{" "}
              <thead>
                {" "}
                <tr>
                  {" "}
                  <th scope="col">Name</th>{" "}
                  <th scope="col">
                    Anbieter,
                    <br />
                    Domain,
                    <br />
                    Typ
                  </th>{" "}
                  <th scope="col">Zweck</th> <th scope="col">Ablauf</th>{" "}
                </tr>{" "}
              </thead>{" "}
              <tbody id="hpp-youtube-cookies">
                {" "}
                <tr>
                  {" "}
                  <td scope="row">
                    yt.innertube::requests, yt.innertube::nextId
                  </td>{" "}
                  <td>
                    <a href="https://policies.google.com/privacy?hl=de">
                      Google
                    </a>
                    <br />
                    www.youtube-nocookie.com
                    <br />
                    LSO
                  </td>{" "}
                  <td>
                    Werden von{" "}
                    <a href="#hpp-youtube">
                      YouTube mit erweitertem Datenschutz
                    </a>{" "}
                    benutzt um eine eindeutige ID zu registrieren, um
                    Statistiken der Videos von YouTube, die der Benutzer gesehen
                    hat, zu merken.
                  </td>{" "}
                  <td>Persistent</td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td scope="row">
                    yt-player-bandwidth, yt-player-headers-readable
                  </td>{" "}
                  <td>
                    <a href="https://policies.google.com/privacy?hl=de">
                      Google
                    </a>
                    <br />
                    www.youtube-nocookie.com
                    <br />
                    LSO
                  </td>{" "}
                  <td>
                    Werden von{" "}
                    <a href="#hpp-youtube">
                      YouTube mit erweitertem Datenschutz
                    </a>{" "}
                    benutzt um die optimale Videoauflösung für den invidiuellen
                    Besucher anhand des Endgeräts und der Netzwerkbedingungen zu
                    ermitteln.
                  </td>{" "}
                  <td>Persistent</td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td scope="row">
                    yt-remote-device-id, ytidb::LAST_RESULT_ENTRY_KEY,
                    yt-remote-connected-devices
                  </td>{" "}
                  <td>
                    <a
                      href="https://policies.google.com/privacy?hl=de"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Google
                    </a>
                    <br />
                    www.youtube-nocookie.com
                    <br />
                    LSO
                  </td>{" "}
                  <td>
                    Werden von{" "}
                    <a href="#hpp-youtube">
                      YouTube mit erweitertem Datenschutz
                    </a>{" "}
                    benutzt um die Benutzereinstellungen beim Abruf eines auf
                    Dritt-Webseiten eingebunden Youtube-Videos zu speichern.
                  </td>{" "}
                  <td>Persistent</td>{" "}
                </tr>{" "}
              </tbody>{" "}
            </table>
          </div>
          <div id="hpp-server_log_files">
            {" "}
            <h3>Server-Log-Dateien</h3>{" "}
            <p>
              Der Provider der Seiten erhebt und speichert automatisch
              Informationen in so genannten Server-Log-Dateien, die Ihr Browser
              automatisch an uns übermittelt. Dies sind:
            </p>{" "}
            <ul>
              {" "}
              <li>Browsertyp und Browserversion</li>{" "}
              <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>{" "}
              <li>Hostname des zugreifenden Rechners</li>{" "}
              <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{" "}
            </ul>{" "}
            <p>
              Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
              nicht vorgenommen.
            </p>{" "}
            <p>
              Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an der technisch fehlerfreien Darstellung und der Optimierung
              seiner Website – hierzu müssen die Server-Log-Files erfasst
              werden.
            </p>
          </div>
          <div id="hpp-contact_form">
            {" "}
            <h3>Kontaktformular</h3>{" "}
            <p>
              Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
              Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
              angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für
              den Fall von Anschlussfragen bei uns gespeichert. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </p>{" "}
            <p>
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
              Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
              Verarbeitung auf unserem berechtigten Interesse an der effektiven
              Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
              DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
              sofern diese abgefragt wurde; die Einwilligung ist jederzeit
              widerrufbar.
            </p>{" "}
            <p>
              Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
              uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
              Speicherung widerrufen oder der Zweck für die Datenspeicherung
              entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
              Zwingende gesetzliche Bestimmungen – insbesondere
              Aufbewahrungsfristen – bleiben unberührt.
            </p>
          </div>
          <div id="hpp-contact_email_phone">
            {" "}
            <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>{" "}
            <p>
              Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
              Ihre Anfrage inklusive aller daraus hervorgehenden
              personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung
              Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </p>{" "}
            <p>
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
              Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
              Verarbeitung auf unserem berechtigten Interesse an der effektiven
              Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
              DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
              sofern diese abgefragt wurde; die Einwilligung ist jederzeit
              widerrufbar.
            </p>{" "}
            <p>
              Die von Ihnen an uns per Kontaktanfragen übersandten Daten
              verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
              Einwilligung zur Speicherung widerrufen oder der Zweck für die
              Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
              Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
              insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
            </p>
          </div>
          <h2 id="hpp-statistics_tools">5. Analyse-Tools</h2>
          <div id="hpp-google_search_console">
            {" "}
            <h3>Google Search Console</h3>{" "}
            <p>
              Wir nutzen den Webanalysedienst Google Search Console. Dieser
              Dienst wird von Google bereitgestellt und ermöglicht uns,
              zahlreiche Einstellungen an Websites vorzunehmen, Statistiken
              einzusehen sowie Optimierungen durchzuführen. Darüber hinaus
              erhalten Webmaster Warnungen bei unnatürlichen Backlinks oder
              Malware, die auf der Website installiert wurde. Im Rahmen der
              Nutzung von Google Search Console werden keine personenbezogenen
              Nutzer- bzw. Trackingdaten verarbeitet oder an Google übermittelt.
              Mehr dazu finden Sie unter diesem Link:{" "}
              <a
                href="https://support.google.com/webmasters/answer/4559176?hl=de"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://support.google.com/webmasters/answer/4559176?hl=de
              </a>
              .
            </p>
          </div>
          <div id="hpp-matomo">
            {" "}
            <h3>Matomo</h3>{" "}
            <p>
              Diese Website benutzt den Open Source Webanalysedienst Matomo.
            </p>{" "}
            <p>
              Mit Hilfe von Matomo sind wir in der Lage Daten über die Nutzung
              unserer Website durch die Websitebesucher zu erfassen und zu
              analysieren. Hierdurch können wir u. a. herausfinden, wann welche
              Seitenaufrufe getätigt wurden und aus welcher Region sie kommen.
              Außerdem erfassen wir verschiedene Logdateien (z. B. IP-Adresse,
              Referrer, verwendete Browser und Betriebssysteme) und können
              messen, ob unsere Websitebesucher bestimmte Aktionen durchführen
              (z. B. Klicks, Käufe u. Ä.).
            </p>{" "}
            <p>
              Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
              Webangebot als auch seine Werbung zu optimieren. Sofern eine
              entsprechende Einwilligung abgefragt wurde, erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die
              Speicherung von Cookies oder den Zugriff auf Informationen im
              Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des
              TTDSG umfasst. Ihre Einwilligung ist jederzeit und mit Wirkung im
              aktuell verwendeten Endgerät/Browser über die{" "}
              <a href="#" className="hc-show-panel">
                Datenschutzeinstellungen
              </a>{" "}
              dieser Websiteπ
              <noscript>
                {" "}
                <mark>
                  <em>
                    (dieser Link ist momentan nicht funktionsfähig, weil Ihr
                    Browser oder ein Browser-Plugin die Ausführung von
                    JavaScript auf dieser Seite verbietet)
                  </em>
                </mark>
              </noscript>{" "}
              widerrufbar. Sie erreichen die Datenschutzeinstellungen auch per
              Klick auf das mitlaufende{" "}
              <a href="#" className="hc-show-icon">
                Datenschutz-Icon
              </a>{" "}
              unten links auf der Website.
            </p>{" "}
            <b>IP-Anonymisierung</b>{" "}
            <p>
              Bei der Analyse mit Matomo setzen wir IP-Anonymisierung ein.
              Hierbei wird Ihre IP-Adresse vor der Analyse gekürzt, sodass Sie
              Ihnen nicht mehr eindeutig zuordenbar ist.
            </p>{" "}
            <b>Cookielose Analyse</b>{" "}
            <p>
              Wir haben Matomo so konfiguriert, dass es keine Cookies in Ihrem
              Browser speichert solange Sie nicht eingewilligt haben. Ihre
              Einwilligung ist jederzeit und mit Wirkung im aktuell verwendeten
              Endgerät/Browser über die{" "}
              <a href="#" className="hc-show-panel">
                Datenschutzeinstellungen
              </a>{" "}
              dieser Website
              <noscript>
                {" "}
                <mark>
                  <em>
                    (dieser Link ist momentan nicht funktionsfähig, weil Ihr
                    Browser oder ein Browser-Plugin die Ausführung von
                    JavaScript auf dieser Seite verbietet)
                  </em>
                </mark>
              </noscript>{" "}
              widerrufbar. Sie erreichen die Datenschutzeinstellungen auch per
              Klick auf das mitlaufende{" "}
              <a href="#" className="hc-show-icon">
                Datenschutz-Icon
              </a>{" "}
              unten links auf der Website.
            </p>{" "}
            <p></p> <b>Hosting</b>{" "}
            <p>
              Wir hosten Matomo bei folgendem Drittanbieter: YUM GmbH,
              Lindleystraße 8A, 60314 Frankfurt am Main.
            </p>{" "}
            <p>
              <b>Auftragsverarbeitung</b>
            </p>{" "}
            <p></p>{" "}
            <p>
              Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur
              Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt
              es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag,
              der gewährleistet, dass dieser die personenbezogenen Daten unserer
              Websitebesucher nur nach unseren Weisungen und unter Einhaltung
              der DSGVO verarbeitet.
            </p>
          </div>
          <h2 id="hpp-external_content_and_plugins">
            6. Externe Inhalte und Plugins
          </h2>
          <div id="hpp-youtube">
            {" "}
            <h3>YouTube mit erweitertem Datenschutz</h3>{" "}
            <p>
              Diese Website bindet Videos der Website YouTube ein. Betreiber der
              Seiten ist die Google Ireland Limited („Google“), Gordon House,
              Barrow Street, Dublin 4, Irland.
            </p>
            <p>
              Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus
              bewirkt laut YouTube, dass YouTube keine Informationen über die
              Besucher auf dieser Website speichert, bevor diese sich das Video
              ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch
              den erweiterten Datenschutzmodus hingegen nicht zwingend
              ausgeschlossen. So stellt YouTube – unabhängig davon, ob Sie sich
              ein Video ansehen – eine Verbindung zum Google
              DoubleClick-Netzwerk her.
            </p>{" "}
            <p>
              Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
              Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
              YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht
              haben. Wenn Sie in Ihrem YouTube-Account eingeloggt sind,
              ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem
              persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem
              Sie sich aus Ihrem YouTube-Account ausloggen.
            </p>{" "}
            <p>
              Des Weiteren kann YouTube nach Starten eines Videos verschiedene
              Cookies auf Ihrem Endgerät speichern oder vergleichbare
              Wiedererkennungstechnologien (z. B. Device-Fingerprinting)
              einsetzen. Auf diese Weise kann YouTube Informationen über
              Besucher dieser Website erhalten. Diese Informationen werden u. a.
              verwendet, um Videostatistiken zu erfassen, die
              Anwenderfreundlichkeit zu verbessern und Betrugsversuchen
              vorzubeugen.
            </p>{" "}
            <p>
              Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
              Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen
              Einfluss haben.
            </p>{" "}
            <p>
              Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
              Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
              Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
              entsprechende Einwilligung abgefragt wurde, erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die
              Speicherung von Cookies oder den Zugriff auf Informationen im
              Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des
              TTDSG umfasst. Ihre Einwilligung ist jederzeit und mit Wirkung im
              aktuell verwendeten Endgerät/Browser über die{" "}
              <a href="#" className="hc-show-panel">
                Datenschutzeinstellungen
              </a>{" "}
              dieser Website
              <noscript>
                {" "}
                <mark>
                  <em>
                    (dieser Link ist momentan nicht funktionsfähig, weil Ihr
                    Browser oder ein Browser-Plugin die Ausführung von
                    JavaScript auf dieser Seite verbietet)
                  </em>
                </mark>
              </noscript>{" "}
              widerrufbar. Sie erreichen die Datenschutzeinstellungen auch per
              Klick auf das mitlaufende{" "}
              <a href="#" className="hc-show-icon">
                Datenschutz-Icon
              </a>{" "}
              unten links auf der Website.
            </p>{" "}
            <p>
              Weitere Informationen über Datenschutz bei YouTube finden Sie in
              deren Datenschutzerklärung unter:{" "}
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p>
          </div>
        </div>
      </BlockWrapper>
    </Layout>
  )
}

export default DatenschutzPage
